import React, { useEffect } from "react"
import CaseStudyHeaderSection from "./components/CaseStudyHeaderSection"
import CaseStudyDescriptionSection from "./components/CaseStudyDescriptionSection"
import CaseStudyWorkSection from "./components/CaseStudyWorkSection"
import NewsletterSection from "../newsletter/NewsletterSection"

const CaseStudyDrStaciTemplate = ({ image }) => {
  useEffect(() => {
    const script1 = document.createElement("script")
    const script2 = document.createElement("script")

    script1.src = "https://fast.wistia.com/embed/medias/l4eb1wk31e.jsonp"
    script2.src = "https://fast.wistia.com/assets/external/E-v1.js"
    document.body.appendChild(script1)
    document.body.appendChild(script2)
  }, [])
  return (
    <React.Fragment>
      <CaseStudyHeaderSection
        title="Marthyn Jovanovic"
        description="Want to Play Like This?"
        image={image}
      />
      <CaseStudyDescriptionSection
        title={
          <h3>
            Marthyn Jovanovic recently came to us looking to scale signups to
            his Drum-Technique Academy, his subscription-based online platform
            where he teaches experienced drummers better technique and mastery.
            <br />
            <br />
            As Marthyn explained to us, while he was getting very positive
            feedback from his students, he was having difficulty communicating
            the full value of his courses to a wider audience. Though his Bass
            Drum Mastery class was selling well, he wasn’t seeing those students
            continue on to sign up for further classes at the Academy.
            <br />
            <br />
            <span>
              How were we going to scale Marthyn’s classes to the widest
              possible audience?
            </span>
            <br />
            <br />
            Our first step was analyzing what was already working and
            understanding why it was a success… even if the scale wasn’t there
            yet. What stood out to us was Marthyn’s video showing off his double
            bass drum technique (complete with live footage from concerts) and
            breaking down his techniques and approach. It was a fantastic Hook
            Point that was clearly resonating with drummers and could be used as
            a starting point to reach an even wider audience.
            <br /> <br />
            The Drum-Technique Academy landing page was a different story.
            Trying to model it after Patreon, Marthyn had three different
            membership tiers for the Academy. But due to the design, potential
            students weren’t clear on which tier was best for them, leaving them
            confused and likely to navigate away from the page. We advised
            Marthyn on how to optimize the landing page, eliminating the tiers
            and using his Bass Drum Mastery class page as a template.
            Additionally, we provided communication design optimization to
            provide more of a guided experience for visitors.
            {/* <span>
              {" "}
              most parents want to do whatever they can to prevent their kids
              from suffering from a chronic disease, where they might not have
              given their kids’ cavities a second thought.
            </span> */}
            <br />
            <br />
            Additionally, we created a content strategy to better utilize his
            YouTube channel. We wanted to position him as a thought leader, so
            we advised him to create content that showcased both his expertise
            and his ability to explain complex concepts in the world of
            drumming. <br />
            <br />
            We also expanded Marthyn’s overall strategy with the following:
          </h3>
        }
        list={
          <ul>
            <li>
              We developed a paid advertising content strategy showing his
              clients playing before signing up for the Academy and after,
              taking viewers on an emotional journey that shows the dramatic
              growth Marthyn’s clients have experienced.
            </li>
            <li>
              Created concepts for bingeable content that breaks down popular
              songs from a drummer’s perspective and explores the musicality and
              history of drums as an instrument.
            </li>
            <li>
              Through our communication analysis, we found that Marthyn was
              communicating with only a small percentage of the population (a
              trend we often see with clients who have not previously
              implemented our communication algorithm). He added our
              communication strategy to his repertoire, and now he’s effectively
              connecting and engaging at scale.
            </li>
            {/* <li>
              Through our communication analysis, we found that Dr. Staci was
              communicating with only a small percentage of the population (a
              common trend with clients who have not previously implemented our
              communication algorithm). She added our communication strategy to
              her repertoire, and now she is effectively connecting and engaging
              at scale.
            </li> */}
          </ul>
        }
        moreInfo={
          <h3>
            {/* So how did it work out for her? Well, Dr. Staci will soon be{" "} */}
            <span>The Results </span>
            After implementing our strategies, Marthyn has seen tremendous
            growth, with both his engagement on social media and subscriptions
            to the Academy. As he told us in a recent follow up call:{" "}
            <span>
              “Hook Point helped me out big time. They saved my company.”
            </span>
            <br />
            <br />
            Marthyn has come a long way, and we’re incredibly proud of all the
            work he’s done. We’re confident that by continuing to implement our
            strategies, he’ll experience even more success in the future.
            <br />
            <br />
            <br />
            {/* //#region */}
            <div
              style={{
                zIndex: 9999,
                display: "flex",
                justifyContent: "center",
                paddingBottom: "100px",
              }}
            >
              <script
                src="https://fast.wistia.net/embed/iframe/rf7vu916w9.jsonp"
                async
              ></script>
              <script
                src="https://fast.wistia.net/assets/external/E-v1.js"
                async
              ></script>
              <span
                className="wistia_embed wistia_async_rf7vu916w9 popover=true popoverAnimateThumbnail=true"
                style={{
                  display: "inline-block",
                  height: "360px",
                  position: "relative",
                  width: "640px",
                  zIndex: 9999,
                }}
              >
                &nbsp;
              </span>
            </div>
            {/*//#regionend */}
          </h3>
        }
      />
      <CaseStudyWorkSection
        next={"/case-study-tylor-swift"}
        text="We’ve got more to tell about our work. "
        subText=" Let’s talk"
      />
      <NewsletterSection />
    </React.Fragment>
  )
}

export default CaseStudyDrStaciTemplate
