import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import CaseStudyDrStaciTemplate from "../modules/caseStudy/caseStudyMarthyn.template"

const CaseStudyDrStaci = ({ data }) => {
  return (
    <Layout>
      <SEO title="Case Study Marthyn Jovanovic" />
      <CaseStudyDrStaciTemplate image={data.caseStudyDrStaci.edges} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    caseStudyDrStaci: allFile(
      filter: { relativeDirectory: { eq: "caseMarthyn" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 900, quality: 100) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`

export default CaseStudyDrStaci
